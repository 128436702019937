import { default as about_45usNqCokbL9aFMeta } from "/opt/atlassian/pipelines/agent/build/pages/about-us.vue?macro=true";
import { default as contactggdDqdGlSrMeta } from "/opt/atlassian/pipelines/agent/build/pages/contact.vue?macro=true";
import { default as favorites6PlaUcDx3pMeta } from "/opt/atlassian/pipelines/agent/build/pages/favorites.vue?macro=true";
import { default as indexOD9t3F2bSJMeta } from "/opt/atlassian/pipelines/agent/build/pages/index.vue?macro=true";
import { default as _91tag_id_93oJErMBtR0ZMeta } from "/opt/atlassian/pipelines/agent/build/pages/news/[tag]/[tag_id].vue?macro=true";
import { default as _91id_93TG0bSvVfbNMeta } from "/opt/atlassian/pipelines/agent/build/pages/news/article/[slug]/[id].vue?macro=true";
import { default as indexPOel7UPVy5Meta } from "/opt/atlassian/pipelines/agent/build/pages/news/index.vue?macro=true";
import { default as newslccLAPUChdMeta } from "/opt/atlassian/pipelines/agent/build/pages/news.vue?macro=true";
import { default as _91id_93EEkSfZnnxuMeta } from "/opt/atlassian/pipelines/agent/build/pages/offices/[slug]/[id].vue?macro=true";
import { default as indexxN6CseWW7GMeta } from "/opt/atlassian/pipelines/agent/build/pages/offices/index.vue?macro=true";
import { default as mapfKlOlybnVdMeta } from "/opt/atlassian/pipelines/agent/build/pages/offices/map.vue?macro=true";
import { default as offices65cJVO7tzdMeta } from "/opt/atlassian/pipelines/agent/build/pages/offices.vue?macro=true";
import { default as our_45services7HVelAMzAZMeta } from "/opt/atlassian/pipelines/agent/build/pages/our-services.vue?macro=true";
import { default as _91id_93FJkFqiZmMuMeta } from "/opt/atlassian/pipelines/agent/build/pages/references/[slug]/[id].vue?macro=true";
import { default as indexUz0AmMSWVTMeta } from "/opt/atlassian/pipelines/agent/build/pages/references/index.vue?macro=true";
import { default as referencesT5zq18HDxHMeta } from "/opt/atlassian/pipelines/agent/build/pages/references.vue?macro=true";
import { default as _91id_9320mq6gGSQLMeta } from "/opt/atlassian/pipelines/agent/build/pages/retail/[slug]/[id].vue?macro=true";
import { default as indexhoBWzu9mFJMeta } from "/opt/atlassian/pipelines/agent/build/pages/retail/index.vue?macro=true";
import { default as mapkhJtOEAao0Meta } from "/opt/atlassian/pipelines/agent/build/pages/retail/map.vue?macro=true";
import { default as retail3o9wcBjZBCMeta } from "/opt/atlassian/pipelines/agent/build/pages/retail.vue?macro=true";
import { default as _91id_93cQ69D2m1LgMeta } from "/opt/atlassian/pipelines/agent/build/pages/warehouses/[slug]/[id].vue?macro=true";
import { default as indexCHarojlpnJMeta } from "/opt/atlassian/pipelines/agent/build/pages/warehouses/index.vue?macro=true";
import { default as map2KImvNuUKTMeta } from "/opt/atlassian/pipelines/agent/build/pages/warehouses/map.vue?macro=true";
import { default as warehouses3lDMEctiIdMeta } from "/opt/atlassian/pipelines/agent/build/pages/warehouses.vue?macro=true";
import { default as component_45stubrz4AibqZDMMeta } from "/opt/atlassian/pipelines/agent/build/node_modules/nuxt/dist/pages/runtime/component-stub?macro=true";
export default [
  {
    name: about_45usNqCokbL9aFMeta?.name ?? "about-us___sk",
    path: about_45usNqCokbL9aFMeta?.path ?? "/o-nas",
    meta: about_45usNqCokbL9aFMeta || {},
    alias: about_45usNqCokbL9aFMeta?.alias || [],
    redirect: about_45usNqCokbL9aFMeta?.redirect,
    component: () => import("/opt/atlassian/pipelines/agent/build/pages/about-us.vue").then(m => m.default || m)
  },
  {
    name: about_45usNqCokbL9aFMeta?.name ?? "about-us___en",
    path: about_45usNqCokbL9aFMeta?.path ?? "/en/about-us",
    meta: about_45usNqCokbL9aFMeta || {},
    alias: about_45usNqCokbL9aFMeta?.alias || [],
    redirect: about_45usNqCokbL9aFMeta?.redirect,
    component: () => import("/opt/atlassian/pipelines/agent/build/pages/about-us.vue").then(m => m.default || m)
  },
  {
    name: contactggdDqdGlSrMeta?.name ?? "contact___sk",
    path: contactggdDqdGlSrMeta?.path ?? "/kontakt",
    meta: contactggdDqdGlSrMeta || {},
    alias: contactggdDqdGlSrMeta?.alias || [],
    redirect: contactggdDqdGlSrMeta?.redirect,
    component: () => import("/opt/atlassian/pipelines/agent/build/pages/contact.vue").then(m => m.default || m)
  },
  {
    name: contactggdDqdGlSrMeta?.name ?? "contact___en",
    path: contactggdDqdGlSrMeta?.path ?? "/en/contact",
    meta: contactggdDqdGlSrMeta || {},
    alias: contactggdDqdGlSrMeta?.alias || [],
    redirect: contactggdDqdGlSrMeta?.redirect,
    component: () => import("/opt/atlassian/pipelines/agent/build/pages/contact.vue").then(m => m.default || m)
  },
  {
    name: favorites6PlaUcDx3pMeta?.name ?? "favorites___sk",
    path: favorites6PlaUcDx3pMeta?.path ?? "/oblubene",
    meta: favorites6PlaUcDx3pMeta || {},
    alias: favorites6PlaUcDx3pMeta?.alias || [],
    redirect: favorites6PlaUcDx3pMeta?.redirect,
    component: () => import("/opt/atlassian/pipelines/agent/build/pages/favorites.vue").then(m => m.default || m)
  },
  {
    name: favorites6PlaUcDx3pMeta?.name ?? "favorites___en",
    path: favorites6PlaUcDx3pMeta?.path ?? "/en/favorites",
    meta: favorites6PlaUcDx3pMeta || {},
    alias: favorites6PlaUcDx3pMeta?.alias || [],
    redirect: favorites6PlaUcDx3pMeta?.redirect,
    component: () => import("/opt/atlassian/pipelines/agent/build/pages/favorites.vue").then(m => m.default || m)
  },
  {
    name: indexOD9t3F2bSJMeta?.name ?? "index___sk",
    path: indexOD9t3F2bSJMeta?.path ?? "/",
    meta: indexOD9t3F2bSJMeta || {},
    alias: indexOD9t3F2bSJMeta?.alias || [],
    redirect: indexOD9t3F2bSJMeta?.redirect,
    component: () => import("/opt/atlassian/pipelines/agent/build/pages/index.vue").then(m => m.default || m)
  },
  {
    name: indexOD9t3F2bSJMeta?.name ?? "index___en",
    path: indexOD9t3F2bSJMeta?.path ?? "/en",
    meta: indexOD9t3F2bSJMeta || {},
    alias: indexOD9t3F2bSJMeta?.alias || [],
    redirect: indexOD9t3F2bSJMeta?.redirect,
    component: () => import("/opt/atlassian/pipelines/agent/build/pages/index.vue").then(m => m.default || m)
  },
  {
    name: newslccLAPUChdMeta?.name ?? undefined,
    path: newslccLAPUChdMeta?.path ?? "/novinky",
    meta: newslccLAPUChdMeta || {},
    alias: newslccLAPUChdMeta?.alias || [],
    redirect: newslccLAPUChdMeta?.redirect,
    component: () => import("/opt/atlassian/pipelines/agent/build/pages/news.vue").then(m => m.default || m),
    children: [
  {
    name: _91tag_id_93oJErMBtR0ZMeta?.name ?? "news-tag-tag_id___sk",
    path: _91tag_id_93oJErMBtR0ZMeta?.path ?? ":tag()/:tag_id()",
    meta: _91tag_id_93oJErMBtR0ZMeta || {},
    alias: _91tag_id_93oJErMBtR0ZMeta?.alias || [],
    redirect: _91tag_id_93oJErMBtR0ZMeta?.redirect,
    component: () => import("/opt/atlassian/pipelines/agent/build/pages/news/[tag]/[tag_id].vue").then(m => m.default || m)
  },
  {
    name: _91id_93TG0bSvVfbNMeta?.name ?? "news-article-slug-id___sk",
    path: _91id_93TG0bSvVfbNMeta?.path ?? "clanok/:slug()/:id()",
    meta: _91id_93TG0bSvVfbNMeta || {},
    alias: _91id_93TG0bSvVfbNMeta?.alias || [],
    redirect: _91id_93TG0bSvVfbNMeta?.redirect,
    component: () => import("/opt/atlassian/pipelines/agent/build/pages/news/article/[slug]/[id].vue").then(m => m.default || m)
  },
  {
    name: indexPOel7UPVy5Meta?.name ?? "news___sk",
    path: indexPOel7UPVy5Meta?.path ?? "/novinky",
    meta: indexPOel7UPVy5Meta || {},
    alias: indexPOel7UPVy5Meta?.alias || [],
    redirect: indexPOel7UPVy5Meta?.redirect,
    component: () => import("/opt/atlassian/pipelines/agent/build/pages/news/index.vue").then(m => m.default || m)
  }
]
  },
  {
    name: newslccLAPUChdMeta?.name ?? undefined,
    path: newslccLAPUChdMeta?.path ?? "/en/news",
    meta: newslccLAPUChdMeta || {},
    alias: newslccLAPUChdMeta?.alias || [],
    redirect: newslccLAPUChdMeta?.redirect,
    component: () => import("/opt/atlassian/pipelines/agent/build/pages/news.vue").then(m => m.default || m),
    children: [
  {
    name: _91tag_id_93oJErMBtR0ZMeta?.name ?? "news-tag-tag_id___en",
    path: _91tag_id_93oJErMBtR0ZMeta?.path ?? ":tag()/:tag_id()",
    meta: _91tag_id_93oJErMBtR0ZMeta || {},
    alias: _91tag_id_93oJErMBtR0ZMeta?.alias || [],
    redirect: _91tag_id_93oJErMBtR0ZMeta?.redirect,
    component: () => import("/opt/atlassian/pipelines/agent/build/pages/news/[tag]/[tag_id].vue").then(m => m.default || m)
  },
  {
    name: _91id_93TG0bSvVfbNMeta?.name ?? "news-article-slug-id___en",
    path: _91id_93TG0bSvVfbNMeta?.path ?? "article/:slug()/:id()",
    meta: _91id_93TG0bSvVfbNMeta || {},
    alias: _91id_93TG0bSvVfbNMeta?.alias || [],
    redirect: _91id_93TG0bSvVfbNMeta?.redirect,
    component: () => import("/opt/atlassian/pipelines/agent/build/pages/news/article/[slug]/[id].vue").then(m => m.default || m)
  },
  {
    name: indexPOel7UPVy5Meta?.name ?? "news___en",
    path: indexPOel7UPVy5Meta?.path ?? "/en/news",
    meta: indexPOel7UPVy5Meta || {},
    alias: indexPOel7UPVy5Meta?.alias || [],
    redirect: indexPOel7UPVy5Meta?.redirect,
    component: () => import("/opt/atlassian/pipelines/agent/build/pages/news/index.vue").then(m => m.default || m)
  }
]
  },
  {
    name: offices65cJVO7tzdMeta?.name ?? undefined,
    path: offices65cJVO7tzdMeta?.path ?? "/kancelarie",
    meta: offices65cJVO7tzdMeta || {},
    alias: offices65cJVO7tzdMeta?.alias || [],
    redirect: offices65cJVO7tzdMeta?.redirect,
    component: () => import("/opt/atlassian/pipelines/agent/build/pages/offices.vue").then(m => m.default || m),
    children: [
  {
    name: _91id_93EEkSfZnnxuMeta?.name ?? "offices-slug-id___sk",
    path: _91id_93EEkSfZnnxuMeta?.path ?? ":slug()/:id()",
    meta: _91id_93EEkSfZnnxuMeta || {},
    alias: _91id_93EEkSfZnnxuMeta?.alias || [],
    redirect: _91id_93EEkSfZnnxuMeta?.redirect,
    component: () => import("/opt/atlassian/pipelines/agent/build/pages/offices/[slug]/[id].vue").then(m => m.default || m)
  },
  {
    name: indexxN6CseWW7GMeta?.name ?? "offices___sk",
    path: indexxN6CseWW7GMeta?.path ?? "/kancelarie",
    meta: indexxN6CseWW7GMeta || {},
    alias: indexxN6CseWW7GMeta?.alias || [],
    redirect: indexxN6CseWW7GMeta?.redirect,
    component: () => import("/opt/atlassian/pipelines/agent/build/pages/offices/index.vue").then(m => m.default || m)
  },
  {
    name: mapfKlOlybnVdMeta?.name ?? "offices-map___sk",
    path: mapfKlOlybnVdMeta?.path ?? "mapa",
    meta: mapfKlOlybnVdMeta || {},
    alias: mapfKlOlybnVdMeta?.alias || [],
    redirect: mapfKlOlybnVdMeta?.redirect,
    component: () => import("/opt/atlassian/pipelines/agent/build/pages/offices/map.vue").then(m => m.default || m)
  }
]
  },
  {
    name: offices65cJVO7tzdMeta?.name ?? undefined,
    path: offices65cJVO7tzdMeta?.path ?? "/en/offices",
    meta: offices65cJVO7tzdMeta || {},
    alias: offices65cJVO7tzdMeta?.alias || [],
    redirect: offices65cJVO7tzdMeta?.redirect,
    component: () => import("/opt/atlassian/pipelines/agent/build/pages/offices.vue").then(m => m.default || m),
    children: [
  {
    name: _91id_93EEkSfZnnxuMeta?.name ?? "offices-slug-id___en",
    path: _91id_93EEkSfZnnxuMeta?.path ?? ":slug()/:id()",
    meta: _91id_93EEkSfZnnxuMeta || {},
    alias: _91id_93EEkSfZnnxuMeta?.alias || [],
    redirect: _91id_93EEkSfZnnxuMeta?.redirect,
    component: () => import("/opt/atlassian/pipelines/agent/build/pages/offices/[slug]/[id].vue").then(m => m.default || m)
  },
  {
    name: indexxN6CseWW7GMeta?.name ?? "offices___en",
    path: indexxN6CseWW7GMeta?.path ?? "/en/offices",
    meta: indexxN6CseWW7GMeta || {},
    alias: indexxN6CseWW7GMeta?.alias || [],
    redirect: indexxN6CseWW7GMeta?.redirect,
    component: () => import("/opt/atlassian/pipelines/agent/build/pages/offices/index.vue").then(m => m.default || m)
  },
  {
    name: mapfKlOlybnVdMeta?.name ?? "offices-map___en",
    path: mapfKlOlybnVdMeta?.path ?? "map",
    meta: mapfKlOlybnVdMeta || {},
    alias: mapfKlOlybnVdMeta?.alias || [],
    redirect: mapfKlOlybnVdMeta?.redirect,
    component: () => import("/opt/atlassian/pipelines/agent/build/pages/offices/map.vue").then(m => m.default || m)
  }
]
  },
  {
    name: our_45services7HVelAMzAZMeta?.name ?? "our-services___sk",
    path: our_45services7HVelAMzAZMeta?.path ?? "/nase-sluzby",
    meta: our_45services7HVelAMzAZMeta || {},
    alias: our_45services7HVelAMzAZMeta?.alias || [],
    redirect: our_45services7HVelAMzAZMeta?.redirect,
    component: () => import("/opt/atlassian/pipelines/agent/build/pages/our-services.vue").then(m => m.default || m)
  },
  {
    name: our_45services7HVelAMzAZMeta?.name ?? "our-services___en",
    path: our_45services7HVelAMzAZMeta?.path ?? "/en/our-services",
    meta: our_45services7HVelAMzAZMeta || {},
    alias: our_45services7HVelAMzAZMeta?.alias || [],
    redirect: our_45services7HVelAMzAZMeta?.redirect,
    component: () => import("/opt/atlassian/pipelines/agent/build/pages/our-services.vue").then(m => m.default || m)
  },
  {
    name: referencesT5zq18HDxHMeta?.name ?? undefined,
    path: referencesT5zq18HDxHMeta?.path ?? "/referencie",
    meta: referencesT5zq18HDxHMeta || {},
    alias: referencesT5zq18HDxHMeta?.alias || [],
    redirect: referencesT5zq18HDxHMeta?.redirect,
    component: () => import("/opt/atlassian/pipelines/agent/build/pages/references.vue").then(m => m.default || m),
    children: [
  {
    name: _91id_93FJkFqiZmMuMeta?.name ?? "references-slug-id___sk",
    path: _91id_93FJkFqiZmMuMeta?.path ?? "/referencia/:slug()/:id()",
    meta: _91id_93FJkFqiZmMuMeta || {},
    alias: _91id_93FJkFqiZmMuMeta?.alias || [],
    redirect: _91id_93FJkFqiZmMuMeta?.redirect,
    component: () => import("/opt/atlassian/pipelines/agent/build/pages/references/[slug]/[id].vue").then(m => m.default || m)
  },
  {
    name: indexUz0AmMSWVTMeta?.name ?? "references___sk",
    path: indexUz0AmMSWVTMeta?.path ?? "/referencie",
    meta: indexUz0AmMSWVTMeta || {},
    alias: indexUz0AmMSWVTMeta?.alias || [],
    redirect: indexUz0AmMSWVTMeta?.redirect,
    component: () => import("/opt/atlassian/pipelines/agent/build/pages/references/index.vue").then(m => m.default || m)
  }
]
  },
  {
    name: referencesT5zq18HDxHMeta?.name ?? undefined,
    path: referencesT5zq18HDxHMeta?.path ?? "/en/references",
    meta: referencesT5zq18HDxHMeta || {},
    alias: referencesT5zq18HDxHMeta?.alias || [],
    redirect: referencesT5zq18HDxHMeta?.redirect,
    component: () => import("/opt/atlassian/pipelines/agent/build/pages/references.vue").then(m => m.default || m),
    children: [
  {
    name: _91id_93FJkFqiZmMuMeta?.name ?? "references-slug-id___en",
    path: _91id_93FJkFqiZmMuMeta?.path ?? "/en/reference/:slug()/:id()",
    meta: _91id_93FJkFqiZmMuMeta || {},
    alias: _91id_93FJkFqiZmMuMeta?.alias || [],
    redirect: _91id_93FJkFqiZmMuMeta?.redirect,
    component: () => import("/opt/atlassian/pipelines/agent/build/pages/references/[slug]/[id].vue").then(m => m.default || m)
  },
  {
    name: indexUz0AmMSWVTMeta?.name ?? "references___en",
    path: indexUz0AmMSWVTMeta?.path ?? "/en/references",
    meta: indexUz0AmMSWVTMeta || {},
    alias: indexUz0AmMSWVTMeta?.alias || [],
    redirect: indexUz0AmMSWVTMeta?.redirect,
    component: () => import("/opt/atlassian/pipelines/agent/build/pages/references/index.vue").then(m => m.default || m)
  }
]
  },
  {
    name: retail3o9wcBjZBCMeta?.name ?? undefined,
    path: retail3o9wcBjZBCMeta?.path ?? "/obchodne-priestory",
    meta: retail3o9wcBjZBCMeta || {},
    alias: retail3o9wcBjZBCMeta?.alias || [],
    redirect: retail3o9wcBjZBCMeta?.redirect,
    component: () => import("/opt/atlassian/pipelines/agent/build/pages/retail.vue").then(m => m.default || m),
    children: [
  {
    name: _91id_9320mq6gGSQLMeta?.name ?? "retail-slug-id___sk",
    path: _91id_9320mq6gGSQLMeta?.path ?? ":slug()/:id()",
    meta: _91id_9320mq6gGSQLMeta || {},
    alias: _91id_9320mq6gGSQLMeta?.alias || [],
    redirect: _91id_9320mq6gGSQLMeta?.redirect,
    component: () => import("/opt/atlassian/pipelines/agent/build/pages/retail/[slug]/[id].vue").then(m => m.default || m)
  },
  {
    name: indexhoBWzu9mFJMeta?.name ?? "retail___sk",
    path: indexhoBWzu9mFJMeta?.path ?? "/obchodne-priestory",
    meta: indexhoBWzu9mFJMeta || {},
    alias: indexhoBWzu9mFJMeta?.alias || [],
    redirect: indexhoBWzu9mFJMeta?.redirect,
    component: () => import("/opt/atlassian/pipelines/agent/build/pages/retail/index.vue").then(m => m.default || m)
  },
  {
    name: mapkhJtOEAao0Meta?.name ?? "retail-map___sk",
    path: mapkhJtOEAao0Meta?.path ?? "mapa",
    meta: mapkhJtOEAao0Meta || {},
    alias: mapkhJtOEAao0Meta?.alias || [],
    redirect: mapkhJtOEAao0Meta?.redirect,
    component: () => import("/opt/atlassian/pipelines/agent/build/pages/retail/map.vue").then(m => m.default || m)
  }
]
  },
  {
    name: retail3o9wcBjZBCMeta?.name ?? undefined,
    path: retail3o9wcBjZBCMeta?.path ?? "/en/retail",
    meta: retail3o9wcBjZBCMeta || {},
    alias: retail3o9wcBjZBCMeta?.alias || [],
    redirect: retail3o9wcBjZBCMeta?.redirect,
    component: () => import("/opt/atlassian/pipelines/agent/build/pages/retail.vue").then(m => m.default || m),
    children: [
  {
    name: _91id_9320mq6gGSQLMeta?.name ?? "retail-slug-id___en",
    path: _91id_9320mq6gGSQLMeta?.path ?? ":slug()/:id()",
    meta: _91id_9320mq6gGSQLMeta || {},
    alias: _91id_9320mq6gGSQLMeta?.alias || [],
    redirect: _91id_9320mq6gGSQLMeta?.redirect,
    component: () => import("/opt/atlassian/pipelines/agent/build/pages/retail/[slug]/[id].vue").then(m => m.default || m)
  },
  {
    name: indexhoBWzu9mFJMeta?.name ?? "retail___en",
    path: indexhoBWzu9mFJMeta?.path ?? "/en/retail",
    meta: indexhoBWzu9mFJMeta || {},
    alias: indexhoBWzu9mFJMeta?.alias || [],
    redirect: indexhoBWzu9mFJMeta?.redirect,
    component: () => import("/opt/atlassian/pipelines/agent/build/pages/retail/index.vue").then(m => m.default || m)
  },
  {
    name: mapkhJtOEAao0Meta?.name ?? "retail-map___en",
    path: mapkhJtOEAao0Meta?.path ?? "map",
    meta: mapkhJtOEAao0Meta || {},
    alias: mapkhJtOEAao0Meta?.alias || [],
    redirect: mapkhJtOEAao0Meta?.redirect,
    component: () => import("/opt/atlassian/pipelines/agent/build/pages/retail/map.vue").then(m => m.default || m)
  }
]
  },
  {
    name: warehouses3lDMEctiIdMeta?.name ?? undefined,
    path: warehouses3lDMEctiIdMeta?.path ?? "/sklady-haly-a-pozemky",
    meta: warehouses3lDMEctiIdMeta || {},
    alias: warehouses3lDMEctiIdMeta?.alias || [],
    redirect: warehouses3lDMEctiIdMeta?.redirect,
    component: () => import("/opt/atlassian/pipelines/agent/build/pages/warehouses.vue").then(m => m.default || m),
    children: [
  {
    name: _91id_93cQ69D2m1LgMeta?.name ?? "warehouses-slug-id___sk",
    path: _91id_93cQ69D2m1LgMeta?.path ?? ":slug()/:id()",
    meta: _91id_93cQ69D2m1LgMeta || {},
    alias: _91id_93cQ69D2m1LgMeta?.alias || [],
    redirect: _91id_93cQ69D2m1LgMeta?.redirect,
    component: () => import("/opt/atlassian/pipelines/agent/build/pages/warehouses/[slug]/[id].vue").then(m => m.default || m)
  },
  {
    name: indexCHarojlpnJMeta?.name ?? "warehouses___sk",
    path: indexCHarojlpnJMeta?.path ?? "/sklady-haly-a-pozemky",
    meta: indexCHarojlpnJMeta || {},
    alias: indexCHarojlpnJMeta?.alias || [],
    redirect: indexCHarojlpnJMeta?.redirect,
    component: () => import("/opt/atlassian/pipelines/agent/build/pages/warehouses/index.vue").then(m => m.default || m)
  },
  {
    name: map2KImvNuUKTMeta?.name ?? "warehouses-map___sk",
    path: map2KImvNuUKTMeta?.path ?? "mapa",
    meta: map2KImvNuUKTMeta || {},
    alias: map2KImvNuUKTMeta?.alias || [],
    redirect: map2KImvNuUKTMeta?.redirect,
    component: () => import("/opt/atlassian/pipelines/agent/build/pages/warehouses/map.vue").then(m => m.default || m)
  }
]
  },
  {
    name: warehouses3lDMEctiIdMeta?.name ?? undefined,
    path: warehouses3lDMEctiIdMeta?.path ?? "/en/warehouses",
    meta: warehouses3lDMEctiIdMeta || {},
    alias: warehouses3lDMEctiIdMeta?.alias || [],
    redirect: warehouses3lDMEctiIdMeta?.redirect,
    component: () => import("/opt/atlassian/pipelines/agent/build/pages/warehouses.vue").then(m => m.default || m),
    children: [
  {
    name: _91id_93cQ69D2m1LgMeta?.name ?? "warehouses-slug-id___en",
    path: _91id_93cQ69D2m1LgMeta?.path ?? ":slug()/:id()",
    meta: _91id_93cQ69D2m1LgMeta || {},
    alias: _91id_93cQ69D2m1LgMeta?.alias || [],
    redirect: _91id_93cQ69D2m1LgMeta?.redirect,
    component: () => import("/opt/atlassian/pipelines/agent/build/pages/warehouses/[slug]/[id].vue").then(m => m.default || m)
  },
  {
    name: indexCHarojlpnJMeta?.name ?? "warehouses___en",
    path: indexCHarojlpnJMeta?.path ?? "/en/warehouses",
    meta: indexCHarojlpnJMeta || {},
    alias: indexCHarojlpnJMeta?.alias || [],
    redirect: indexCHarojlpnJMeta?.redirect,
    component: () => import("/opt/atlassian/pipelines/agent/build/pages/warehouses/index.vue").then(m => m.default || m)
  },
  {
    name: map2KImvNuUKTMeta?.name ?? "warehouses-map___en",
    path: map2KImvNuUKTMeta?.path ?? "map",
    meta: map2KImvNuUKTMeta || {},
    alias: map2KImvNuUKTMeta?.alias || [],
    redirect: map2KImvNuUKTMeta?.redirect,
    component: () => import("/opt/atlassian/pipelines/agent/build/pages/warehouses/map.vue").then(m => m.default || m)
  }
]
  },
  {
    name: component_45stubrz4AibqZDMMeta?.name ?? undefined,
    path: component_45stubrz4AibqZDMMeta?.path ?? "/sitemap.xml",
    meta: component_45stubrz4AibqZDMMeta || {},
    alias: component_45stubrz4AibqZDMMeta?.alias || [],
    redirect: component_45stubrz4AibqZDMMeta?.redirect,
    component: () => import("/opt/atlassian/pipelines/agent/build/node_modules/nuxt/dist/pages/runtime/component-stub").then(m => m.default || m)
  }
]